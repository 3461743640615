import { EntityId } from "@jackfruit/common"
import { useSelector, shallowEqual } from "react-redux"
import { productPagesSelectors } from "~/redux/state/productPages"
import { RootState } from "~/redux/store"

export const useProductPages = (ids: EntityId[]) => {
  const productPages = useSelector((state: RootState) =>
    productPagesSelectors.selectByIds(state, ids)
  )

  return { productPages }
}

export const useProductPagesWithShallowEqual = (ids: EntityId[]) => {
  const productPages = useSelector(
    (state: RootState) => productPagesSelectors.selectByIds(state, ids),
    shallowEqual
  )

  return { productPages }
}
