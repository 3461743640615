import { EntityId } from "@jackfruit/common"
import { useSelector, shallowEqual } from "react-redux"
import { imageRegionsSelectors } from "~/redux/state/imageRegions"
import { RootState } from "~/redux/store"

export const useImageRegions = (ids: EntityId[]) => {
  const imageRegions = useSelector((state: RootState) =>
    imageRegionsSelectors.selectByIds(state, ids)
  )

  return {
    imageRegions,
  }
}

export const useImageRegionsWithShallowEqual = (ids: EntityId[]) => {
  const imageRegions = useSelector(
    (state: RootState) => imageRegionsSelectors.selectByIds(state, ids),
    shallowEqual
  )

  return {
    imageRegions,
  }
}
